import { useToast } from '@chakra-ui/react';
import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../services/api';
import { useProcessos } from './processos';

type ProcessoDocumentoAdicionar = {
  descricao: string;
  file?: File;
  link?: string;
};

type ProcessoDocumento = {
  id_processo_documento: number;
  descricao: string;
  link?: string;
  url?: string;
};

type ProcessoDocumentoContextData = {
  processoDocumento?: ProcessoDocumento;
  updateProcessoDocumento(data: Partial<ProcessoDocumento>): void;
  adicionarProcessoDocumento(data: ProcessoDocumentoAdicionar): Promise<number>;
  deletarProcessoDocumento(id: number): Promise<number>;
};

const ProcessoDocumentoContext = createContext(
  {} as ProcessoDocumentoContextData,
);

const ProcessoDocumentoProvider: React.FC = ({ children }) => {
  const toast = useToast();
  const { processo } = useProcessos();

  const [processoDocumento, setProcessoDocumento] =
    useState<ProcessoDocumento>();

  const updateProcessoDocumento = useCallback(
    (data: Partial<ProcessoDocumento>) => {
      setProcessoDocumento({
        ...processoDocumento,
        ...(data as ProcessoDocumento),
      });
    },
    [processoDocumento],
  );

  const adicionarProcessoDocumento = useCallback(
    async ({ file, descricao, link }: ProcessoDocumentoAdicionar) => {
      try {
        let formData: FormData | ProcessoDocumentoAdicionar = {
          descricao,
          link,
        };

        if (file) {
          formData = new FormData();

          formData.append('file', file);
          formData.append('descricao', descricao);
          if (link) formData.append('link', link);
        }

        const { status } = await api.post(
          `processos/${processo?.id_processo}/documentos`,
          formData,
        );

        toast({
          title: 'Sucesso!',
          description: 'Documento anexado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return status;
      } catch (error) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return error.response.status;
      }
    },
    [processo, toast],
  );

  const deletarProcessoDocumento = useCallback(
    async (id: number) => {
      try {
        const { status } = await api.delete(`documentos/${id}`);
        toast({
          title: 'Sucesso!',
          description: 'Documento deletado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
        return status;
      } catch (error) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return error.response.status;
      }
    },
    [toast],
  );

  return (
    <ProcessoDocumentoContext.Provider
      value={{
        processoDocumento,
        adicionarProcessoDocumento,
        deletarProcessoDocumento,
        updateProcessoDocumento,
      }}
    >
      {children}
    </ProcessoDocumentoContext.Provider>
  );
};

export default ProcessoDocumentoProvider;

export const useProcessoDocumento = () => useContext(ProcessoDocumentoContext);
