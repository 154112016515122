import { useToast } from '@chakra-ui/react';
import { createContext, useCallback, useContext, useState } from 'react';
import api from '../services/api';
import { useProcessos } from './processos';

type ProcessoMappAdicionar = { ids_mapps: number[] };

type ProcessoMapp = {
  id_processo: number;
  id_mapp: number;
};

type ProcessoMappContextData = {
  processoMapp?: ProcessoMapp;
  adicionarProcessoMapp(data: ProcessoMappAdicionar): Promise<number>;
};

const ProcessoMappContext = createContext({} as ProcessoMappContextData);

const ProcessoMappsProvider: React.FC = ({ children }) => {
  const toast = useToast();
  const { processo } = useProcessos();

  const [processoMapp, setProcessoMapp] = useState<ProcessoMapp>();

  const adicionarProcessoMapp = useCallback(
    async (data: ProcessoMappAdicionar) => {
      try {
        const { status } = await api.post(
          `processos/${processo?.id_processo}/mapps`,
          data,
        );

        return status;
      } catch (error) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return error.response.status;
      }
    },
    [processo, toast],
  );

  return (
    <ProcessoMappContext.Provider
      value={{ adicionarProcessoMapp, processoMapp }}
    >
      {children}
    </ProcessoMappContext.Provider>
  );
};

export default ProcessoMappsProvider;

export const useProcessoMapps = () => useContext(ProcessoMappContext);
