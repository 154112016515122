import { AuthProvider } from './auth';
import { PromocoesProvider } from './promocoes';
import { CandidatosProvider } from './candidatos';
import { FiFaProvider } from './fisfas';
import { TafsProvider } from './tafs';
import { MembrosProvider } from './membros';
import { DocumentosProvider } from './documentos';
import { JulgamentosProvider } from './julgamentos';
import { SidebarDrawerProvider } from './SidebarDrawerContext';
import { SimbolosProvider } from './simbolos';
import { CargosProvider } from './cargos';
import { TransacoesProvider } from './transacoes';
import { ClassificacoesProvider } from './classificacoes';
import { ProcessosProvider } from './processos';
import ProcessoDocumentoProvider from './processo.documento';
import ProcessoMappsProvider from './processo.mapp';
import { FontesProvider } from './fontes';
import { MappsProvider } from './mapps';

const AppContextProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <PromocoesProvider>
      <CandidatosProvider>
        <FiFaProvider>
          <TafsProvider>
            <DocumentosProvider>
              <MembrosProvider>
                <JulgamentosProvider>
                  <SidebarDrawerProvider>
                    <CargosProvider>
                      <MappsProvider>
                        <ClassificacoesProvider>
                          <ProcessosProvider>
                            <ProcessoMappsProvider>
                              <ProcessoDocumentoProvider>
                                <TransacoesProvider>
                                  <FontesProvider>{children}</FontesProvider>
                                </TransacoesProvider>
                              </ProcessoDocumentoProvider>
                            </ProcessoMappsProvider>
                          </ProcessosProvider>
                        </ClassificacoesProvider>
                      </MappsProvider>
                    </CargosProvider>
                  </SidebarDrawerProvider>
                </JulgamentosProvider>
              </MembrosProvider>
            </DocumentosProvider>
          </TafsProvider>
        </FiFaProvider>
      </CandidatosProvider>
    </PromocoesProvider>
  </AuthProvider>
);

export default AppContextProvider;
