import { useToast } from '@chakra-ui/react';
import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../services/api';

type ProcessoCriar = {
  id_opm: number;
  proc_numero: string;
  proc_sistema_tramitacao: string;
  modalidade: string;
  vigencia?: Date;
  proc_status: string;
  proc_data_inicial: Date;
  proc_objeto: string;
  proc_objeto_desc: string;
};

type ProcessoItemCriar = {
  id_compra: number;
  descricao: string | undefined;
  codigo?: string;
  id_categoria: number;
  id_processo_mapp?: number;
  id_subcategoria: number;
  id_unidade_medida: number;
  quantidade: number;
  item_valor: number;
};

type Processo = ProcessoCriar & {
  id_processo: number;
  proc_status: string;
  modalidade: string;
  vigencia?: Date;
  proc_responsabilidade: number;
  opm: {
    uni_sigla: string;
  };
};

type ProcessoTramitacaoCriar = { tipo_tramitacao: 0 | 1; destino: string };

type ProcessoContextData = {
  processo?: Processo;
  updateProcesso(processo: Partial<Processo>): void;
  cadastrarProcesso(data: ProcessoCriar): Promise<number>;
  mostrarProcesso(id: number): Promise<void>;
  cadastrarProcessoItem(data: ProcessoItemCriar): Promise<number>;
  cadastrarProcessoTramitacao(data: ProcessoTramitacaoCriar): Promise<number>;
  loadProcessoStorage(): Promise<void>;
};

const ProcessosContext = createContext({} as ProcessoContextData);

export const ProcessosProvider: React.FC = ({ children }) => {
  const toast = useToast();

  const [processo, setProcesso] = useState<Processo>();

  const updateProcesso = (data: Partial<Processo>) => {
    setProcesso(data as Processo);

    if (data.id_processo)
      sessionStorage.setItem(
        '@pmce-cetic-sigecom:id_processo',
        JSON.stringify(data?.id_processo),
      );
  };

  const mostrarProcesso = async (id: number): Promise<void> => {
    const {
      data: { vigencia, proc_data_inicial, ...restProcesso },
    } = await api.get<Processo>(`processos/${id}`);

    sessionStorage.setItem(
      '@pmce-cetic-sigecom:id_processo',
      JSON.stringify(id),
    );

    updateProcesso({
      ...restProcesso,
      id_processo: id,
      vigencia: vigencia ? new Date(vigencia) : undefined,
      proc_data_inicial: new Date(proc_data_inicial),
    });
  };

  const loadProcessoStorage = async () => {
    const id = sessionStorage.getItem('@pmce-cetic-sigecom:id_processo');

    if (id && !processo)
      await mostrarProcesso(Number.parseInt(id as string, 10));
  };

  const cadastrarProcesso = async (data: ProcessoCriar) => {
    try {
      const {
        status,
        data: { vigencia, proc_data_inicial, ...restProcesso },
      } = await api.post<Processo>('processos', data);
      toast({
        title: 'Sucesso!',
        description: 'Processo criado com sucesso.',
        status: 'success',
        duration: 15000,
        isClosable: true,
        position: 'top-right',
      });

      updateProcesso({
        ...restProcesso,
        vigencia: vigencia ? new Date(vigencia) : undefined,
        proc_data_inicial: new Date(proc_data_inicial),
      });

      return status;
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response
          ? error.response.data.message
          : 'Ocorreu um erro',
        status: 'error',
        duration: 15000,
        isClosable: true,
        position: 'top-right',
      });

      return error.response.status;
    }
  };

  const cadastrarProcessoItem = async (
    data: ProcessoItemCriar,
  ): Promise<number> => {
    try {
      const { status } = await api.post(
        `processos/${processo?.id_processo}/itens`,
        data,
      );

      toast({
        title: 'Sucesso!',
        description: 'Item adicionado com sucesso.',
        status: 'success',
        duration: 15000,
        isClosable: true,
        position: 'top-right',
      });
      return status;
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response
          ? error.response.data.message
          : 'Ocorreu um erro',
        status: 'error',
        duration: 15000,
        isClosable: true,
        position: 'top-right',
      });

      return error.response.status;
    }
  };

  const cadastrarProcessoTramitacao = useCallback(
    async (data: ProcessoTramitacaoCriar) => {
      try {
        const { status } = await api.post(
          `processos/${processo?.id_processo}/tramitacoes`,
          data,
        );
        toast({
          title: 'Sucesso!',
          description: 'Tramitação criada com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return status;
      } catch (error) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return error.response.status;
      }
    },
    [processo, toast],
  );

  return (
    <ProcessosContext.Provider
      value={{
        cadastrarProcesso,
        processo,
        updateProcesso,
        mostrarProcesso,
        cadastrarProcessoItem,
        cadastrarProcessoTramitacao,
        loadProcessoStorage,
      }}
    >
      {children}
    </ProcessosContext.Provider>
  );
};

export const useProcessos = () => useContext(ProcessosContext);
