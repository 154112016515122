import { useToast } from '@chakra-ui/toast';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '../services/api';

type TransacaoCriar = {
  criado_por: string;
  id_fonte_recurso: number;
  id_mapp: number;
  tran_tipo_operacao: string;
  tran_valor: number;
};
type TransacaoEditar = {
  id_transacao_: number;
  transacao_nome?: string;
  transacao_municipio?: string;
  transacao_status?: string;
  transacao_esfera?: string;
  transacao_codigo?: string;
  atualizado_por: string;
};

type Transacao = {
  id_transacao_: number;
  sim_nome: string;
  sim_valor: number;
  sim_status: string;
  criado_por: string;
};

type TransacoesContextData = {
  transacoes: any[];
  transacao: Transacao | undefined;
  adicionaTransacao(dados: TransacaoCriar): Promise<any>;
  editarTransacao(dados: TransacaoEditar): Promise<any>;
  removerTransacao(id_transacao: number): Promise<any>;
  carregarTransacao(id_transacao: number): Promise<void>;
};

const TransacoesContext = createContext({} as TransacoesContextData);

interface TransacoesProviderProps {
  children: ReactNode;
}

export function TransacoesProvider({ children }: TransacoesProviderProps) {
  const toast = useToast();

  // eslint-disable-next-line
  const [transacoes, setTransacoes] = useState([]);
  const [transacao, setTransacao] = useState<Transacao | undefined>(undefined);

  const adicionaTransacao = useCallback(
    async (dados: TransacaoCriar): Promise<any> => {
      try {
        const response = await api.post(`transacoes/`, dados);

        toast({
          title: 'Sucesso!',
          description: 'Transacao criado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const editarTransacao = useCallback(
    async (dados: TransacaoEditar): Promise<any> => {
      try {
        const response = await api.put(`transacoess/`, dados);

        toast({
          title: 'Sucesso!',
          description: 'Transacao de  editada com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const removerTransacao = useCallback(
    async (id_transacao: number): Promise<any> => {
      try {
        const response = await api.delete(`transacoess/${id_transacao}`);

        toast({
          title: 'Sucesso!',
          description: 'Transacao de  removido com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const carregarTransacao = useCallback(
    async (id_transacao: number): Promise<void> => {
      try {
        const { data } = await api.get(`promocoes/${id_transacao}`);

        setTransacao(data);
      } catch (error) {}
    },
    [],
  );

  return (
    <TransacoesContext.Provider
      value={{
        transacoes,
        transacao,
        carregarTransacao,
        adicionaTransacao,
        editarTransacao,
        removerTransacao,
      }}
    >
      {children}
    </TransacoesContext.Provider>
  );
}

export const useTransacoes = () => useContext(TransacoesContext);
