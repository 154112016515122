import { useToast } from '@chakra-ui/toast';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '../services/api';

// type AtualizarTitulacao = {
//   id_titulacao: string;
//   pes_codigo: string;
//   id_tipo_titulacao: string;
//   data_conclusao: string;
//   instituicao: string;
//   nome_curso: string;
//   data_referencia?: string;
//   publicacao?: string;
//   bol_codigo?: string;
//   titulo_tcc?: string;
//   file_boletim: File[];
//   file_doc_conclusao: File[];
// };

// type AtualizarCurso = {
//   id_curso_interesse: number;
//   id_tipo_curso: number;
//   id_fifa: string;
//   pes_codigo: string;
//   id_curso?: number;
//   nome_curso?: string;
//   data_conclusao: string;
//   nota?: number;
//   classe_carga_horaria?: string;
//   bol_codigo?: string;
//   publicacao?: string;
//   file_boletim: File[];
//   file_certificado: File[];
// };

// type AtualizarCondecoracao = {
//   id_condecoracao_medalha_fifa: number;
//   id_condecoracao_medalha: number;
//   id_fifa: string;
//   pes_codigo: string;
//   bol_codigo?: string;
//   publicacao?: string;
//   file_boletim: File[];
// };

// type AtualizarTrabalhoRelevante = {
//   id_trabalho_relevante_fifa: number;
//   id_fifa: string;
//   pes_codigo: string;
//   nome: string;
//   bol_codigo?: string;
//   publicacao?: string;
//   file_boletim: File[];
// };

type Item =
  | 'cursoInteresse'
  | 'cursoHabilitacao'
  | 'graduacao'
  | 'pos'
  | 'condecoracao'
  | 'trabalho'
  | 'condutor'
  | 'procedimento'
  | 'contrato'
  | 'escala'
  | 'produtividade'
  | 'taf'
  | 'local'
  | 'ferimento'
  | 'punicao'
  | 'falta'
  | 'condenacao';
interface InvalidarItemFiFa {
  id_fifa: string;
  tipo_item: Item;
  id_item: number;
  id_resposta_inv_corr?: number;
  resposta_homologacao?: string;
}

interface IReverterInvalidacaoItemFiFa {
  id_fifa: string;
  tipo_item: Item;
  id_item: number;
}

interface FiFa {
  id_fifa: string;
  pes_codigo: string;
  id_status_fifa: number;
  pontuacao_positiva: number;
  pontuacao_negativa: number;
  pontuacao_positiva_homologar: number;
  pontuacao_negativa_homologar: number;
  itens_validar: number;
  itens_homologar: number;
  tipo_documento: string;
  pessoa: {
    pes_nome: string;
  };
  status: {
    slug: string;
  };
  impedimentos: {
    id_impedimento_fifa: number;
    id_tipo_impedimento: number;
    id_status_item_fifa: number;
    status: {
      slug: string;
    };
  }[];
  candidato?: {
    id_candidato: number;
    id_promocao: string;
    situacao: string;
    noPeriodoAlteracao: boolean;
    noPeriodoRecursoOpm: boolean;
    noPeriodoRecursoComissao: boolean;
    promocao: {
      ano: string;
      data_limite_alteracao_fifa: string;
      data_limite_validacao_fifa: string;
      tipo_promocao: {
        id_tipo_promocao: number;
        titulo: string;
      };
      tipo_quadro: {
        sigla: string;
        nome: string;
      };
    };
  };
  policial: {
    pm_data_entrada: string;
    graduacao: {
      gra_codigo: number;
      gra_sigla: string;
    };
    quadro: {
      qua_codigo: number;
      qua_sigla: string;
    };
  };
  promocao?: {
    pmp_data_promocao: string;
  };
  promocaoOficial?: {
    pmp_data_promocao: string;
    gra_promocao: number;
  };
}

type FiFaContextData = {
  policialAtual?: string;
  candidatoAtual?: number;
  fifa?: FiFa;
  loadFifa(pes_codigo: string): Promise<void>;
  validarFifa(id_fifa: string): Promise<void>;
  invalidarItemFiFa(
    dados: InvalidarItemFiFa,
    onClose: () => void,
  ): Promise<void>;
  reverterInvalidacaoItemFiFa(
    dados: IReverterInvalidacaoItemFiFa,
    onClose: () => void,
  ): Promise<void>;
  homologarFifa(id_fifa: string): Promise<void>;
  atualizarStatusFifa(id_fifa: string, status: string): Promise<void>;
  enviarParaValidacao(id_fifa: string): Promise<void>;
  setFifa(ficha: FiFa | undefined): void;
  setCandidatoAtual(id_candidato: number): void;
  selecionaPolicialAtual(pes_codigo: string): void;
};

const FiFaContext = createContext({} as FiFaContextData);

interface FiFaProviderProps {
  children: ReactNode;
}

export function FiFaProvider({ children }: FiFaProviderProps) {
  const toast = useToast();
  const [policialAtual, setPolicialAtual] = useState<string>();
  const [candidatoAtual, setCandidatoAtual] = useState<number>();
  const [fifa, setFifa] = useState<FiFa>();

  const loadFifa = useCallback(async (pes_codigo: string): Promise<void> => {
    try {
      const response = await api.get(`/fisfas/${pes_codigo}`);
      setFifa(response.data);
    } catch (error: any) {}
  }, []);

  const validarFifa = useCallback(
    async (id_fifa: string): Promise<void> => {
      try {
        await api.put(`fisfas/${id_fifa}/validar`);

        if (fifa) {
          setFifa({ ...fifa, status: { ...fifa.status, slug: 'homologavel' } });
        }

        toast({
          title: 'Sucesso!',
          description: 'Ficha/Folha validada com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, fifa],
  );

  const invalidarItemFiFa = useCallback(
    async (
      { id_fifa, id_item, ...rest }: InvalidarItemFiFa,
      onClose: () => void,
    ): Promise<void> => {
      try {
        const resposta = await api.put(
          `fisfas/invalidar/${id_fifa}/item/${id_item}`,
          { ...rest },
        );

        onClose();

        if (fifa) {
          setFifa({
            ...fifa,
            id_status_fifa: resposta.data.id_status_fifa,
            pontuacao_negativa: resposta.data.pontuacao_negativa,
            pontuacao_positiva: resposta.data.pontuacao_positiva,
            pontuacao_negativa_homologar:
              resposta.data.pontuacao_negativa_homologar,
            pontuacao_positiva_homologar:
              resposta.data.pontuacao_positiva_homologar,
            itens_homologar: resposta.data.itens_homologar,
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Item invalidado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, fifa],
  );

  const reverterInvalidacaoItemFiFa = useCallback(
    async (
      { id_fifa, id_item, ...rest }: IReverterInvalidacaoItemFiFa,
      onClose: () => void,
    ): Promise<void> => {
      try {
        const resposta = await api.put(
          `fisfas/reverter/invalidacao/${id_fifa}/item/${id_item}`,
          { ...rest },
        );

        onClose();

        if (fifa) {
          setFifa({
            ...fifa,
            pontuacao_negativa: resposta.data.pontuacao_negativa,
            pontuacao_positiva: resposta.data.pontuacao_positiva,
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Invalidação revertida com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, fifa],
  );

  const enviarParaValidacao = useCallback(
    async (id_fifa: string): Promise<void> => {
      try {
        await api.put(`fisfas/${id_fifa}/enviarParaValidacao`);

        if (fifa) {
          setFifa({
            ...fifa,
            id_status_fifa: 2,
            status: { ...fifa.status, slug: 'validavel' },
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Ficha/Folha foi enviada para validação com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, fifa],
  );

  const atualizarStatusFifa = useCallback(
    async (id_fifa: string, status: string): Promise<void> => {
      try {
        const response = await api.put(`fisfas/${id_fifa}/status/${status}`);

        if (fifa) {
          setFifa({
            ...fifa,
            id_status_fifa: response.data.status.id_status_fifa,
            status: response.data.status,
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Status da ficha alterado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, fifa],
  );

  const homologarFifa = useCallback(
    async (id_fifa: string): Promise<void> => {
      try {
        const response = await api.put(`fisfas/${id_fifa}/homologar`);

        const fifaResposta = response.data.fifa;
        const { status } = response.data;

        if (fifa) {
          setFifa({
            ...fifa,
            id_status_fifa: fifaResposta.id_status_fifa,
            pontuacao_negativa: fifaResposta.pontuacao_negativa,
            pontuacao_positiva: fifaResposta.pontuacao_positiva,
            pontuacao_negativa_homologar:
              fifaResposta.pontuacao_negativa_homologar,
            pontuacao_positiva_homologar:
              fifaResposta.pontuacao_positiva_homologar,
            itens_homologar: fifaResposta.itens_homologar,
            status,
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Ficha homologada com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, fifa],
  );

  const selecionaPolicialAtual = useCallback((pes_codigo: string) => {
    setPolicialAtual(pes_codigo);
  }, []);

  return (
    <FiFaContext.Provider
      value={{
        selecionaPolicialAtual,
        invalidarItemFiFa,
        reverterInvalidacaoItemFiFa,
        policialAtual,
        candidatoAtual,
        setCandidatoAtual,
        atualizarStatusFifa,
        fifa,
        setFifa,
        loadFifa,
        validarFifa,
        homologarFifa,
        enviarParaValidacao,
      }}
    >
      {children}
    </FiFaContext.Provider>
  );
}

export const useFiFa = () => useContext(FiFaContext);
