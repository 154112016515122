import React from 'react';
import { MdEdit, MdDashboard, MdSearch } from 'react-icons/md';
import { Box, Flex } from '@chakra-ui/layout';
import { Accordion } from '@chakra-ui/react';
import { Logo } from './Logo';
import MenuItem from './Menu';
import MenuDropdown from './MenuDropdown';
import { Footer } from './Footer';
import { useAuth } from '../../../../contexts/auth';

interface ISideBarProps {
  activated: boolean;
  handleActiveSideBar(): void;
}

const cadastrosCeticItems = [
  {
    label: 'FONTES RECURSOS',
    to: '/cadastro/fontesrecurso',
  },
  {
    label: 'MAPPS',
    to: '/cadastro/mapp',
  },
  { label: 'PROCESSO', to: '/cadastro/processo' },
];

const cadastrosCecomItems = [{ label: 'PROCESSO', to: '/cadastro/processo' }];
const consultaCecomItems = [{ label: 'PROCESSO', to: '/consulta/processos' }];
const consultaOpmItems = [{ label: 'PROCESSO', to: '/consulta/processosopm' }];

const consultasCeticItems = [
  { label: 'FONTES RECURSOS', to: '/cadastro/fontesrecurso' },
  { label: 'PROCESSO', to: '/consulta/processos' },
];

const consultasComandoItems = [
  { label: 'PROCESSO', to: '/consulta/processos' },
];

const bcgGrupos = [
  'SIGECOM - OPM',
  'SIGECOM - CETIC',
  'SIGECOM - COGEPRO',
  'SIGECOM - DPGI',
  'SIGECOM - CECOM',
];
const admGrupos = ['SIGECOM - CETIC', 'SIGECOM - DPGI', 'SIGECOM - COGEPRO'];

const SideBar: React.FC<ISideBarProps> = ({
  activated,
  handleActiveSideBar,
}) => {
  const { user } = useAuth();

  return (
    <Flex
      minW={activated ? '250px' : '60px'}
      minHeight="100%"
      direction="column"
      boxShadow="4px 0px 9px -4px rgba(0, 0, 0, 0.71)"
      className="Sidebar"
      transition="min-width 0.6s"
    >
      <Box
        display="block"
        h="80px"
        w="100%"
        bg="#fff"
        fontSize="22px"
        fontWeight="800"
        transition="visibility 0.5s"
      >
        <Logo activated={activated} />
      </Box>

      <Flex bg="green.500" direction="column" alignItems="initial" flex="1">
        <Accordion allowToggle>
          {bcgGrupos.includes(String(user.currentPerfil)) && (
            <MenuItem
              to="/home"
              label="INICIAL"
              icon={MdDashboard}
              activated={activated}
            />
          )}
          {admGrupos.includes(String(user.currentPerfil)) && (
            <>
              <MenuDropdown
                label="CADASTRO"
                icon={MdEdit}
                items={cadastrosCeticItems}
                activated={activated}
                handleActiveSideBar={handleActiveSideBar}
              />
              <MenuDropdown
                label="CONSULTA"
                icon={MdSearch}
                items={consultasCeticItems}
                activated={activated}
                handleActiveSideBar={handleActiveSideBar}
              />
            </>
          )}
          {user.currentPerfil === 'SIGECOM - OPM' && (
            <>
              <MenuDropdown
                label="CONSULTA"
                icon={MdSearch}
                items={consultaOpmItems}
                activated={activated}
                handleActiveSideBar={handleActiveSideBar}
              />
            </>
          )}
          {user.currentPerfil === 'SIGECOM - CECOM' && (
            <>
              <MenuDropdown
                label="CADASTRO"
                icon={MdEdit}
                items={cadastrosCecomItems}
                activated={activated}
                handleActiveSideBar={handleActiveSideBar}
              />
              <MenuDropdown
                label="CONSULTA"
                icon={MdSearch}
                items={consultaCecomItems}
                activated={activated}
                handleActiveSideBar={handleActiveSideBar}
              />
            </>
          )}
        </Accordion>
      </Flex>
      <Footer activated={activated} />
    </Flex>
  );
};

export default SideBar;
