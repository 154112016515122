import { useToast } from '@chakra-ui/toast';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '../services/api';

type MappCriar = {
  mapp_nome: string;
  mapp_codigo: string;
  criado_por: string;
  subclassificacoes: [];
};
type MappEditar = {
  id_mapp_recurso: number;
  mapp_nome?: string;
  mapp_municipio?: string;
  mapp_status?: string;
  mapp_esfera?: string;
  mapp_codigo?: string;
  atualizado_por: string;
};

type Mapp = {
  id_mapp_recurso: number;
  sim_nome: string;
  sim_valor: number;
  sim_status: string;
  criado_por: string;
};

type MappsContextData = {
  mapps: any[];
  mapp: Mapp | undefined;
  adicionaMapp(dados: MappCriar): Promise<any>;
  editarMapp(dados: MappEditar): Promise<any>;
  carregarSaldoMapp(id_mapp: number): Promise<string>;
  removerMapp(id_mapp: number): Promise<any>;
  carregarMapp(id_mapp: number): Promise<void>;
};

const MappsContext = createContext({} as MappsContextData);

interface MappsProviderProps {
  children: ReactNode;
}

export function MappsProvider({ children }: MappsProviderProps) {
  const toast = useToast();

  // eslint-disable-next-line
  const [mapps, setMapps] = useState([]);
  const [mapp, setMapp] = useState<Mapp | undefined>(undefined);

  const adicionaMapp = useCallback(
    async (dados: MappCriar): Promise<any> => {
      try {
        const response = await api.post(`mapps/`, dados);

        toast({
          title: 'Sucesso!',
          description: 'Mapp criado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const editarMapp = useCallback(
    async (dados: MappEditar): Promise<any> => {
      try {
        const response = await api.put(`mappsrecursos/`, dados);

        toast({
          title: 'Sucesso!',
          description: 'Mapp de  editada com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const removerMapp = useCallback(
    async (id_mapp: number): Promise<any> => {
      try {
        const response = await api.delete(`mappsrecursos/${id_mapp}`);

        toast({
          title: 'Sucesso!',
          description: 'Mapp de recurso removido com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const carregarMapp = useCallback(async (id_mapp: number): Promise<void> => {
    try {
      const { data } = await api.get(`promocoes/${id_mapp}`);

      setMapp(data);
    } catch (error) {}
  }, []);

  const carregarSaldoMapp = useCallback(
    async (id_mapp: number): Promise<string> => {
      try {
        const response = await api.get(`/transacoes/datatable/${id_mapp}`);
        const mappSaldoReduce = await response.data.items.reduce(
          (valorAtual: any, objeto: any) => {
            return Number(objeto.tran_valor) + Number(valorAtual);
          },
          0,
        );
        /**
         * Formatação de numero para formato de dinheiro
         */
        return new Intl.NumberFormat('pt-BR', {
          currency: 'BRL',
          style: 'currency',
        }).format(parseFloat(mappSaldoReduce));
      } catch (error) {
        return '0';
      }
    },
    [],
  );

  return (
    <MappsContext.Provider
      value={{
        mapps,
        mapp,
        carregarMapp,
        carregarSaldoMapp,
        adicionaMapp,
        editarMapp,
        removerMapp,
      }}
    >
      {children}
    </MappsContext.Provider>
  );
}

export const useMapps = () => useContext(MappsContext);
