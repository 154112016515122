import { useToast } from '@chakra-ui/toast';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '../services/api';

type FonteRecursoCriar = {
  fonte_nome: string;
  fonte_municipio?: string;
  fonte_esfera: string;
  fonte_codigo: string;
  criado_por: string;
};
type FonteRecursoEditar = {
  id_fonte_recurso: number;
  fonte_nome?: string;
  fonte_municipio?: string;
  fonte_status?: string;
  fonte_esfera?: string;
  fonte_codigo?: string;
  atualizado_por: string;
};

type Simbolo = {
  id_fonte_recurso: number;
  sim_nome: string;
  sim_valor: number;
  sim_status: string;
  criado_por: string;
};

type FontesContextData = {
  fontes: any[];
  fonte: Simbolo | undefined;
  adicionaFonteRecurso(dados: FonteRecursoCriar): Promise<any>;
  editarFonteRecurso(dados: FonteRecursoEditar): Promise<any>;
  removerFonte(id_fonte: number): Promise<any>;
  carregarSimbolo(id_fonte: number): Promise<void>;
};

const FontesContext = createContext({} as FontesContextData);

interface FontesProviderProps {
  children: ReactNode;
}

export function FontesProvider({ children }: FontesProviderProps) {
  const toast = useToast();

  // eslint-disable-next-line
  const [fontes, setFontes] = useState([]);
  const [fonte, setFonte] = useState<Simbolo | undefined>(undefined);

  const adicionaFonteRecurso = useCallback(
    async (dados: FonteRecursoCriar): Promise<any> => {
      try {
        const response = await api.post(`fontesrecursos/`, dados);

        toast({
          title: 'Sucesso!',
          description: 'Fonte de Recurso criada com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const editarFonteRecurso = useCallback(
    async (dados: FonteRecursoEditar): Promise<any> => {
      try {
        const response = await api.put(`fontesrecursos/`, dados);

        toast({
          title: 'Sucesso!',
          description: 'Fonte de Recurso editada com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const removerFonte = useCallback(
    async (id_fonte: number): Promise<any> => {
      try {
        const response = await api.delete(`fontesrecursos/${id_fonte}`);

        toast({
          title: 'Sucesso!',
          description: 'Fonte de recurso removido com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const carregarSimbolo = useCallback(
    async (id_fonte: number): Promise<void> => {
      try {
        const { data } = await api.get(`promocoes/${id_fonte}`);

        setFonte(data);
      } catch (error) {}
    },
    [],
  );

  return (
    <FontesContext.Provider
      value={{
        fontes,
        fonte,
        carregarSimbolo,
        adicionaFonteRecurso,
        editarFonteRecurso,
        removerFonte,
      }}
    >
      {children}
    </FontesContext.Provider>
  );
}

export const useFontes = () => useContext(FontesContext);
