import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

const SignIn = lazy(
  () =>
    import(
      /* webpackChunkName: "signin" */
      '../pages/signin'
    ),
);

const Dashboard = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard" */
      '../pages/dashboard'
    ),
);
const CadastrarFonteRecurso = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard" */
      '../pages/fontesRecursos/cadastrar'
    ),
);
const CadastrarMapp = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard" */
      '../pages/mapps/cadastrar'
    ),
);

const CadastrarClassificacao = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard" */
      '../pages/classificacoes/cadastrar'
    ),
);

const CadastrarProcesso = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard" */
      '../pages/processo/cadastrar'
    ),
);

const EditarProcesso = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard" */
      '../pages/processo/editar'
    ),
);
const FinalizarProcesso = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard" */
      '../pages/processo/finalizar'
    ),
);
const EditarProcessoOpm = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard" */
      '../pages/processoopm/editar'
    ),
);

const ListarProcesso = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard" */
      '../pages/processo/listar'
    ),
);
const ListarProcessoOpm = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard" */
      '../pages/processoopm/listar'
    ),
);

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/home" component={Dashboard} isPrivate hasRole={['*']} />
      <Route
        path="/cadastro/fontesrecurso"
        component={CadastrarFonteRecurso}
        isPrivate
        hasRole={['*']}
      />
      <Route
        path="/cadastro/mapp"
        component={CadastrarMapp}
        isPrivate
        hasRole={['*']}
      />

      <Route
        path="/cadastro/classificacoes"
        component={CadastrarClassificacao}
        isPrivate
        hasRole={['*']}
      />

      <Route
        path="/cadastro/processo"
        component={CadastrarProcesso}
        isPrivate
        hasRole={['*']}
      />

      <Route
        path="/editar/processo"
        component={EditarProcesso}
        isPrivate
        hasRole={['*']}
      />
      <Route
        path="/finalizar/processo"
        component={FinalizarProcesso}
        isPrivate
        hasRole={['*']}
      />
      <Route
        path="/editar/processoopm"
        component={EditarProcessoOpm}
        isPrivate
        hasRole={['*']}
      />

      <Route
        path="/consulta/processos"
        component={ListarProcesso}
        isPrivate
        hasRole={['*']}
      />
      <Route
        path="/consulta/processosopm"
        component={ListarProcessoOpm}
        isPrivate
        hasRole={['*']}
      />
    </Switch>
  );
};

export default Routes;
