import { useToast } from '@chakra-ui/toast';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '../services/api';

type Documento = {
  id_manual_documento: number;
  titulo: string;
  categoria: string;
  path_documento: string;
};

type CriarDocumento = {
  titulo: string;
  categoria: string;
  file_documento: File[];
};

type DocumentosContextData = {
  documentos: Documento[];
  removerDocumento(id_manual_documento: number): Promise<any>;
  adicionarDocumento(dados: CriarDocumento): Promise<any>;
  carregarDocumentos(): Promise<void>;
};

const DocumentosContext = createContext({} as DocumentosContextData);

interface DocumentosProviderProps {
  children: ReactNode;
}

export function DocumentosProvider({ children }: DocumentosProviderProps) {
  const toast = useToast();
  // eslint-disable-next-line
  const [documentos, setDocumentos] = useState<Documento[]>([]);

  const removerDocumento = useCallback(
    async (id_manual_documento: number): Promise<any> => {
      try {
        const response = await api.delete(`manuais/${id_manual_documento}`);

        setDocumentos(response.data);

        toast({
          title: 'Sucesso!',
          description: 'Documento removido com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const adicionarDocumento = useCallback(
    async (dados: CriarDocumento): Promise<void> => {
      const formData = new FormData();
      formData.append('titulo', dados.titulo);

      formData.append('categoria', dados.categoria);

      if (dados.file_documento && dados.file_documento[0]) {
        const file = dados.file_documento[0];
        const blob = file.slice(0, file.size, file.type);
        const newFile = new File([blob], 'documento.pdf', { type: file.type });
        formData.append('files', newFile);
      }

      try {
        const response = await api.post(`manuais`, formData);
        setDocumentos(response.data);

        toast({
          title: 'Sucesso!',
          description: 'Documento adicionado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const carregarDocumentos = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get(`manuais`);

      setDocumentos(data);
    } catch (error) {}
  }, []);

  return (
    <DocumentosContext.Provider
      value={{
        documentos,
        carregarDocumentos,
        adicionarDocumento,
        removerDocumento,
      }}
    >
      {children}
    </DocumentosContext.Provider>
  );
}

export const useDocumentos = () => useContext(DocumentosContext);
