import { Img } from '@chakra-ui/image';
import { Flex, Text } from '@chakra-ui/layout';
import logoCetic from '../../../../assets/logo-cetic.png';

export function Footer() {
  return (
    <Flex
      as="footer"
      w="100%"
      minH="140px"
      bg="#fafafa"
      borderTopWidth="6px"
      borderTopStyle="solid"
      borderTopColor="#008127"
    >
      <Flex
        direction="column"
        w="100%"
        align="center"
        justify="center"
        fontSize="11px"
        px="15px"
        pt="0px"
        pb="10px"
        lineHeight="14px"
      >
        <Img
          id="logotipo"
          src={logoCetic}
          alt="logo cetic"
          maxWidth="70px"
          mt="15px"
          mb="10px"
          mx="auto"
        />

        <Text fontWeight="bold" mb=" 4px">
          CÉLULA DE TECNOLOGIA DA INFORMAÇÃO E COMUNICAÇÃO DA PMCE
        </Text>
        <Text>Av. Aguanambi, 2280 - Fátima - Fortaleza - CE</Text>
        <Text>
          Fone: 85 3101.3570 / 3101.3571 - E-mail: informatica@pm.ce.gov.br
        </Text>
        <Text>Horário de funcionamento</Text>
        <Text>De segunda a sexta de 8h às 12h e de 13h às 17h</Text>
      </Flex>
    </Flex>
  );
}
