import { useToast } from '@chakra-ui/toast';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '../services/api';

type CargoCriar = {
  car_nome: string;
  id_simbolo: number;
  criado_por: string;
};

type Cargo = {
  id_cargo: number;
  id_simbolo: string;
  car_status: string;
  criado_por: string;
};

type CargosContextData = {
  cargos: any[];
  cargo: Cargo | undefined;
  adicionaCargo(dados: CargoCriar): Promise<any>;
  removerCargo(id_cargo: number): Promise<any>;
  carregarCargo(id_cargo: number): Promise<void>;
};

const CargosContext = createContext({} as CargosContextData);

interface CargosProviderProps {
  children: ReactNode;
}

export function CargosProvider({ children }: CargosProviderProps) {
  const toast = useToast();
  // eslint-disable-next-line
  const [cargos, setCargos] = useState([]);
  const [cargo, setCargo] = useState<Cargo | undefined>(undefined);

  const adicionaCargo = useCallback(
    async (dados: CargoCriar): Promise<any> => {
      try {
        const response = await api.post(`cargos/`, dados);

        toast({
          title: 'Sucesso!',
          description: 'Cargo criado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const removerCargo = useCallback(
    async (id_cargo: number): Promise<any> => {
      try {
        const response = await api.delete(`cargos/${id_cargo}`);

        toast({
          title: 'Sucesso!',
          description: 'Cargo removido com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const carregarCargo = useCallback(async (id_cargo: number): Promise<void> => {
    try {
      const { data } = await api.get(`promocoes/${id_cargo}`);

      setCargo(data);
    } catch (error) {}
  }, []);

  return (
    <CargosContext.Provider
      value={{
        cargos,
        cargo,
        carregarCargo,
        adicionaCargo,
        removerCargo,
      }}
    >
      {children}
    </CargosContext.Provider>
  );
}

export const useCargos = () => useContext(CargosContext);
