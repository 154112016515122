import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

type Taf = {
  id_taf_fifa: number;
  id_desempenho_taf: number;
  ano: string;
  id_fifa: string;
  pes_codigo: string;
  pes_nome: string;
};

type TafsContextData = {
  tafSelecionado: Taf | undefined;
  selecionarTaf(taf: Taf): void;
};

const TafsContext = createContext({} as TafsContextData);

interface TafsProviderProps {
  children: ReactNode;
}

export function TafsProvider({ children }: TafsProviderProps) {
  // eslint-disable-next-line

  const [tafSelecionado, setTafSelecionado] = useState<Taf | undefined>(
    undefined,
  );

  const selecionarTaf = useCallback(async (taf: Taf): Promise<void> => {
    setTafSelecionado(taf);
  }, []);

  return (
    <TafsContext.Provider
      value={{
        tafSelecionado,
        selecionarTaf,
      }}
    >
      {children}
    </TafsContext.Provider>
  );
}

export const useTafs = () => useContext(TafsContext);
