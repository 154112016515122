import { useToast } from '@chakra-ui/react';
import React, { createContext, useCallback, useContext } from 'react';
import api from '../services/api';

type ClassificacaoCriar = {
  nomes: string[];
};

type ClassificacoesContextData = {
  adicionarClassificacoes(data: ClassificacaoCriar): Promise<number>;
};

const ClassificacoesContext = createContext({} as ClassificacoesContextData);

export const ClassificacoesProvider: React.FC = ({ children }) => {
  const toast = useToast();

  const adicionarClassificacoes = useCallback(
    async (data: ClassificacaoCriar): Promise<number> => {
      try {
        const { status } = await api.post('classificacoes', data);
        toast({
          title: 'Sucesso!',
          description: 'Classificacoes criadas com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return status;
      } catch (error) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return error.response.status;
      }
    },
    [toast],
  );

  return (
    <ClassificacoesContext.Provider
      value={{
        adicionarClassificacoes,
      }}
    >
      {children}
    </ClassificacoesContext.Provider>
  );
};

export const useClassificacoes = () => useContext(ClassificacoesContext);
